import { useLazyQuery, useResult } from '@vue/apollo-composable';
import historyListScheme from '@/modules/property-history/api/historyList.graphql';
import { ref, watch } from '@vue/composition-api';
import historyItems from '@/utils/list-generators/history/historyItems';
import linkedObjectScheme from '@/modules/linked-objects/api/linked-object.graphql';
import { savedLat, savedLon } from '@/provider/utils';

export function useHistoryList({ ids, fromDate, toDate, monitoringItem }) {
  const noMoreData = ref(false);
  const preventUpdateFromSubscribe = ref(false);
  const variables = ref({
    first: 100,
    after: null,
    filter: {
      objectId: {
        equalTo: monitoringItem.id
      },
      recordedAt: {
        greaterThanOrEqualTo: fromDate,
        lessThanOrEqualTo: toDate
      }
    }
  });

  if (ids.length) {
    variables.value.filter.propertyId = {
      in: ids
    };
  }

  if (!fromDate) {
    const today = new Date();
    variables.value.filter.recordedAt.greaterThanOrEqualTo = new Date(
      today - 1
    ).toISOString();
  }

  if (!toDate) {
    variables.value.filter.recordedAt.lessThanOrEqualTo = new Date().toISOString();
  }
  const {
    result,
    load,
    restart,
    refetch,
    loading,
    subscribeToMore
  } = useLazyQuery(historyListScheme.fetchHistoryListConnection, variables, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network'
  });

  watch(
    () => loading.value,
    () => {
      noMoreData.value = false;
    }
  );

  const objectSchemaPropertyDescription = useLazyQuery(
    linkedObjectScheme.fetch,
    {
      id: monitoringItem.id
    },
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only'
    }
  );

  const pageInfo = useResult(
    result,
    {
      endCursor: null,
      hasNextPage: true
    },
    data => {
      return data.objectPropertiesHistoriesConnection.pageInfo;
    }
  );
  const loadMore = () => {
    if (!result.value) {
      load();
    } else {
      if (pageInfo.value.hasNextPage) {
        variables.value.after = pageInfo.value.endCursor;
      } else {
        noMoreData.value = true;
      }
    }
  };

  const historyList = useResult(result, [], data => {
    return data.objectPropertiesHistoriesConnection.edges.map(item =>
      historyItems[monitoringItem.type]({
        node: { ...item.node, property: getDescriptionForProperty(item.node) }
      })
    );
  });

  const points = useResult(result, [], data => {
    return data.objectPropertiesHistoriesConnection.edges
      .filter(
        edge => !(isNaN(edge.node?.value?.lon) || isNaN(edge.node?.value?.lat))
      )
      .map(edge => [
        savedLon(edge.node?.value?.lon),
        savedLat(edge.node?.value?.lat)
      ]);
  });

  const getDescriptionForProperty = item => {
    return (
      schemaPropertyDescriptions.value.find(
        itemInner => itemInner.property === item.property
      )?.description || `${item.property}`
    );
  };

  const schemaPropertyDescriptions = useResult(
    objectSchemaPropertyDescription.result,
    [],
    data => {
      return data.object?.schema?.schemaProperties;
    }
  );

  subscribeToMore({
    document: historyListScheme.listenHistoryObjectProperty,
    variables: {
      id: monitoringItem.id
    },
    updateQuery: (previousResult, { subscriptionData }) => {
      console.log('history list subscription');
      if (preventUpdateFromSubscribe.value) return;
      const relatedNode = subscriptionData.data?.Objects?.relatedNode;
      if (!relatedNode) return;
      if (relatedNode.__typename === 'ObjectProperty') {
        // if node similar to position property then update query
        if (!isNaN(relatedNode.value?.lon) && !isNaN(relatedNode.value?.lat)) {
          variables.value.filter.recordedAt.lessThanOrEqualTo = new Date().toISOString();
        }
      }
    }
  });

  return {
    load,
    restart,
    historyList,
    points,
    variables,
    pageInfo,
    loadMore,
    result,
    refetch,
    loading,
    noMoreData,
    loadDescriptions: objectSchemaPropertyDescription.load,
    loadDescriptionsOnResult: objectSchemaPropertyDescription.onResult,
    preventUpdateFromSubscribe
  };
}
